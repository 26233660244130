// Navs
.nav {
  display: flex;
  flex-direction: column;

  .nav-item {
    a {
      color: $gray-color-dark;
      padding: .6rem .8rem;
      text-decoration: none;
      &:focus,
      &:hover {
        color: $primary-color;
      }
    }
    &.active {
      & > a {
        font-weight: bold;
        &:focus,
        &:hover {
          color: $primary-color;
        }
      }
    }
  }

  & .nav {
    margin-bottom: 1rem;
    margin-left: 2rem;

    a {
      color: $gray-color;
    }
  }
}
