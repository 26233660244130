// Navigation
.breadcrumb,
.tab,
.pagination,
.nav {
  list-style: none;
  margin: .5rem 0;
}

@import 'breadcrumbs';
@import 'tabs';
@import 'pagination';
@import 'navs';
