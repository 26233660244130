// Tabs
.tab {
  align-items: center;
  display: flex;
  flex-wrap: wrap;

  .tab-item {
    margin-top: 0;

    &.tab-action {
      flex: 1 0 auto;
      text-align: right;
    }

    a {
      border-bottom: .2rem solid transparent;
      color: inherit;
      display: block;
      margin-top: 0;
      padding: .6rem 1.2rem .4rem 1.2rem;
      text-decoration: none;
      &:focus,
      &:hover {
        color: $link-color;
      }
    }
    &.active a,
    a.active {
      border-bottom-color: $primary-color;
      color: $link-color;
    }
  }

  &.tab-block {
    .tab-item {
      flex: 1 0 0;
      text-align: center;

      .badge {
        &[data-badge]::after {
          position: absolute;
          right: -.4rem;
          top: -.4rem;
          transform: translate(0, 0);
        }
      }
    }
  }

  &:not(.tab-block) {
    .badge {
      padding-right: .2rem;
    }
  }
}
