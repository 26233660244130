// Base
*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: $html-font-size;
  line-height: $html-line-height;
  -webkit-tap-highlight-color: transparent;
}

body {
  background: $body-bg;
  color: $body-font-color;
  font-family: $body-font-family;
  font-size: $font-size;
  overflow-x: hidden;
  text-rendering: optimizeLegibility;
  font-weight: 300;
}

a {
  color: $link-color;
  outline: none;
  text-decoration: none;

  &:focus {
    @include control-shadow();
  }

  &:focus,
  &:hover,
  &:active,
  &.active {
    color: $link-color-dark;
    text-decoration: none !important;
  }
}
