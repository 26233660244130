// Toasts
.toast {
  border: $border-width solid $dark-color;
  border-radius: $border-radius;
  color: $light-color;
  display: block;
  padding: $layout-padding;
  @include toast-variant($dark-color);
  width: 100%;

  &.toast-primary {
    @include toast-variant($primary-color);
  }

  &.toast-success {
    @include toast-variant($control-color-success);
  }

  &.toast-warning {
    @include toast-variant($control-color-warning);
  }

  &.toast-error {
    @include toast-variant($control-color-error);
  }

  a {
    color: $light-color;
    text-decoration: underline;
    &:focus,
    &:hover,
    &:active,
    &.active {
      opacity: .75;
    }
  }
}
