// Codes
code {
  @include label-base();
  @include label-variant($code-color, lighten($code-color, 33%));
}

.code {
  border-radius: $border-radius;
  color: $body-font-color;
  line-height: 2rem;
  position: relative;

  &::before {
    content: attr(data-lang);
    color: $gray-color;
    font-size: $font-size-sm;
    position: absolute;
    right: 1rem;
    top: .2rem;
  }

  code {
    background: $bg-color;
    color: inherit;
    display: block;
    line-height: inherit;
    overflow-x: auto;
    padding: 2rem;
    width: 100%;
  }
}
