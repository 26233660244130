// Breadcrumbs
.breadcrumb {
  padding: 1.2rem;

  .breadcrumb-item {
    display: inline-block;
    margin: 0;

    &:last-child {
      color: $gray-color;
    }
    &:not(:first-child) {
      &::before {
        color: $gray-color-light;
        content: "/";
        padding: 0 .4rem;
      }
    }
  }
}
