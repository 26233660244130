// Bars
.bar {
  background: $bg-color-dark;
  border-radius: $border-radius;
  display: flex;
  flex-wrap: nowrap;
  height: 1.6rem;
  width: 100%;

  &.bar-sm {
    height: .4rem;
  }

  // TODO: attr() support
  .bar-item {
    background: $primary-color;
    color: $light-color;
    display: block;
    font-size: $font-size-sm;
    flex-shrink: 0;
    line-height: 1;
    height: 100%;
    padding: .2rem 0;
    position: relative;
    text-align: center;
    width: 0;

    &:first-child {
      border-bottom-left-radius: $border-radius;
      border-top-left-radius: $border-radius;
    }
    &:last-child {
      border-bottom-right-radius: $border-radius;
      border-top-right-radius: $border-radius;
      flex-shrink: 1;
    }
  }
}

// Slider bar
.bar-slider {
  height: .2rem;
  margin: 1rem 0;
  position: relative;

  .bar-item {
    left: 0;
    padding: 0;
    position: absolute;
    &:not(:last-child):first-child {
      background: $bg-color-dark;
      z-index: $zindex-0;
    }
  }

  .bar-slider-btn {
    background: $primary-color;
    border: 0;
    border-radius: 50%;
    height: 1.2rem;
    padding: 0;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(50%, -50%);
    width: 1.2rem;

    &:active {
      box-shadow: 0 0 0 .2rem $primary-color;
    }
  }
}
