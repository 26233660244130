// Core mixins
@mixin clearfix() {
  &::after {
    clear: both;
    content: "";
    display: table;
  }
}

// Component focus shadow
@mixin control-shadow($color: $primary-color) {
  box-shadow: 0 0 0 .2rem rgba($color, .15);
}

// Component transition
@mixin control-transition() {
  transition: all .2s ease;
}

// Toast variant
@mixin toast-variant($color: $dark-color) {
  background: rgba($color, .9);
  border-color: $color;
}

// Label base style
@mixin label-base() {
  border-radius: $border-radius;
  display: inline-block;
  line-height: 1;
  padding: .3rem .4rem;
  vertical-align: baseline;
}

@mixin label-variant($color: $light-color, $bg-color: $primary-color) {
  background: $bg-color;
  color: $color;
}

@mixin shadow-variant($offset) {
  box-shadow: 0 $offset ($offset+.1rem)*2 rgba($dark-color, 0.3);
}
