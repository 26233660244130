// Divider
.divider,
.divider-vert {
  display: block;
  position: relative;

  &[data-content]::after {
    background: $bg-color-light;
    color: $gray-color-light;
    content: attr(data-content);
    display: inline-block;
    font-size: $font-size-sm;
    padding: 0 .8rem;
    transform: translateY(-1.1rem);
  }
}

.divider {
  border-top: $border-width solid $border-color;
  height: .1rem;
  margin: 1rem 0;
}

.divider-vert {
  display: block;
  padding: $layout-padding;

  &::before {
    border-left: $border-width solid $border-color;
    bottom: $layout-padding;
    content: "";
    display: block;
    left: 50%;
    position: absolute;
    top: $layout-padding;
    transform: translateX(-50%);
  }

  &[data-content]::after {
    left: 50%;
    padding: .4rem 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

// Loading
.loading {
  color: transparent !important;
  min-height: 1.6rem;
  pointer-events: none;
  position: relative;
  &::after {
    animation: loading 500ms infinite linear;
    border: .2rem solid $primary-color;
    border-radius: .8rem;
    border-right-color: transparent;
    border-top-color: transparent;
    content: "";
    display: block;
    height: 1.6rem;
    left: 50%;
    margin-left: -.8rem;
    margin-top: -.8rem;
    position: absolute;
    top: 50%;
    width: 1.6rem;
    z-index: $zindex-0;
  }
}

// Position
.clearfix {
  @include clearfix();
}
.float-left {
  float: left !important;
}
.float-right {
  float: right !important;
}

.rel {
  position: relative;
}
.abs {
  position: absolute;
}
.fixed {
  position: fixed;
}
.centered {
  display: block;
  float: none;
  margin-left: auto;
  margin-right: auto;
}

// Spacing
.mt-10 {
  margin-top: 1rem;
}
.mr-10 {
  margin-right: 1rem;
}
.mb-10 {
  margin-bottom: 1rem;
}
.ml-10 {
  margin-left: 1rem;
}
.mt-5 {
  margin-top: .5rem;
}
.mr-5 {
  margin-right: .5rem;
}
.mb-5 {
  margin-bottom: .5rem;
}
.ml-5 {
  margin-left: .5rem;
}

.pt-10 {
  padding-top: 1rem;
}
.pr-10 {
  padding-right: 1rem;
}
.pb-10 {
  padding-bottom: 1rem;
}
.pl-10 {
  padding-left: 1rem;
}
.pt-5 {
  padding-top: .5rem;
}
.pr-5 {
  padding-right: .5rem;
}
.pb-5 {
  padding-bottom: .5rem;
}
.pl-5 {
  padding-left: .5rem;
}

// Display
.block {
  display: block;
}
.inline {
  display: inline;
}
.inline-block {
  display: inline-block;
}
.flex {
  display: flex;
}
.inline-flex {
  display: inline-flex;
}
.hide {
  display: none !important;
}
.visible {
  visibility: visible;
}
.invisible {
  visibility: hidden;
}
.text-hide {
  background: transparent;
  border: 0;
  color: transparent;
  font-size: 0;
  line-height: 0;
  text-shadow: none;
}
.text-assistive {
  border: 0;
  clip: rect(0,0,0,0);
  height: .1rem;
  margin: -.1rem;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: .1rem;
}
// Text
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-justify {
  text-align: justify;
}

.text-lowercase {
  text-transform: lowercase;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-capitalize {
  text-transform: capitalize;
}

.text-normal {
  font-weight: normal;
}
.text-bold {
  font-weight: bold;
}
.text-italic {
  font-style: italic;
}
.text-large {
  font-size: 1.2em;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-clip {
  overflow: hidden;
  text-overflow: clip;
  white-space: nowrap;
}
.text-break {
  hyphens: auto;
  word-break: break-word;
  word-wrap: break-word;
}
.hand {
  cursor: pointer;
}

// Shadow
.shadow-0 {
  @include shadow-variant(0);
}
.shadow-1 {
  @include shadow-variant(.1rem);
}
.shadow-2 {
  @include shadow-variant(.2rem);
}
.shadow-3 {
  @include shadow-variant(.3rem);
}
.shadow-4 {
  @include shadow-variant(.4rem);
}

// Shapes
.rounded {
  border-radius: $border-radius;
}
.circle {
  border-radius: 50%;
}
