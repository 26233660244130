// Avatars
.avatar {
  background: $primary-color;
  border-radius: 50%;
  color: rgba($light-color, 0.85);
  display: inline-block;
  font-size: $font-size;
  font-weight: 300;
  height: 3.2rem;
  line-height: 1;
  margin: 0;
  position: relative;
  vertical-align: middle;
  width: 3.2rem;

  &.avatar-xs {
    font-size: .8rem;
    height: 1.6rem;
    width: 1.6rem;
  }
  &.avatar-sm {
    font-size: 1rem;
    height: 2.4rem;
    width: 2.4rem;
  }
  &.avatar-lg {
    font-size: 2rem;
    height: 4.8rem;
    width: 4.8rem;
  }
  &.avatar-xl {
    font-size: 2.6rem;
    height: 6.4rem;
    width: 6.4rem;
  }

  img {
    border-radius: 50%;
    height: 100%;
    position: relative;
    width: 100%;
    z-index: $zindex-1;
  }

  .avatar-icon {
    background: $bg-color-light;
    bottom: 14.64%;
    height: 50%;
    padding: .2rem;
    position: absolute;
    right: 14.64%;
    transform: translate(50%, 50%);
    width: 50%;
  }

  &[data-initial]::before {
    color: currentColor;
    content: attr(data-initial);
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: $zindex-0;
  }
}
