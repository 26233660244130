// Modals
.modal {
  align-items: center;
  bottom: 0;
  display: none;
  justify-content: center;
  left: 0;
  opacity: 0;
  overflow: hidden;
  padding: $layout-padding;
  position: fixed;
  right: 0;
  top: 0;

  &:target,
  &.active {
    display: flex;
    opacity: 1;
    z-index: $zindex-4;

    .modal-overlay {
      background: rgba($dark-color, .5);
      bottom: 0;
      cursor: default;
      display: block;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }

    .modal-container {
      animation: slide-down .2s ease 1;
      max-width: 64rem;
      z-index: $zindex-0;
    }
  }

  &.modal-sm {
    .modal-container {
      max-width: 32rem;
    }
  }
}

.modal-container {
  background: $bg-color-light;
  border-radius: $border-radius;
  display: block;
  padding: 0;
  @include shadow-variant(.4rem);
  text-align: left;

  .modal-header {
    padding: $layout-padding-lg;

    .modal-title {
      font-size: $font-size-lg;
      margin: 0;
    }
  }

  .modal-body {
    max-height: 50vh;
    overflow-y: auto;
    padding: $layout-padding-lg;
    position: relative;
  }

  .modal-footer {
    padding: $layout-padding-lg;
    text-align: right;
  }
}
