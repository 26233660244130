// Autocomplete
.form-autocomplete {
  position: relative;

  .form-autocomplete-input {
    align-content: flex-start;
    display: flex;
    flex-wrap: wrap;
    height: auto;
    min-height: 3.2rem;
    padding: .1rem 0 0 .1rem;

    &.is-focused {
      border-color: $primary-color;
      @include control-shadow();
    }

    .form-input {
      border-color: transparent;
      box-shadow: none;
      display: inline-block;
      flex: 1 0 auto;
      height: 2.8rem;
      padding: .3rem .8rem;
      width: auto;
    }
  }

  mark {
    font-size: 1;
    padding: .1em 0;
  }

  .menu {
    left: 0;
    position: absolute;
    top: 100%;
    width: 100%;
  }
}
